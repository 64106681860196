import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserBase } from '../../types/user-base';
import { UserRepo } from '../../types/user-repo';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {

  user: UserBase;
  repos: UserRepo[];
  username: string;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private location: Location
  ) { }

  ngOnInit() {
    this.repos = [];
    this.username = this.route.snapshot.paramMap.get('username');
    this.getUserDetails();
    this.getUserRepos();
  }
  getUserDetails(): void {
    this.usersService.username = this.username;
    this.usersService.getUserBaseInfo().subscribe(
      (response) => {
        this.user = response;
      });
  }
  getUserRepos(): void {
    this.usersService.username = this.username;
    this.usersService.getUserRepos().subscribe(
      (response) => {
        this.repos = response;
      });
  }

  goBack(): void {
    this.location.back();
  }


}
