import { Injectable } from '@angular/core';
import { User } from '../types/user';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  hint = '';
  initialUsers: User[] = [];

  constructor() { }

  search(): User[] {

    if (this.hint.length > 1) {
      const parts: string[] = this.hint.split(' ');
      return this.initialUsers.filter(function (user: User) {
        let found = true;
        for (let i = 0; i < parts.length; i++) {
          if (user.login.toLowerCase().indexOf(parts[i].toLowerCase()) === -1) {
            found = false;
          }
        }
        return found;
      }, this);

    } else {
      return this.initialUsers;
    }

  }

}
