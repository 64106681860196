import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../types/user';
import { UserBase } from '../../../types/user-base';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent implements OnInit {

  @Input() user: User;
  @Input() userDetail: UserBase;

  constructor() { }

  ngOnInit() {
  }

}
