import { Component, OnInit } from '@angular/core';
import { User } from '../../types/user';
import { UserBase } from '../../types/user-base';
import { UsersService } from '../../services/users.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-users',
  templateUrl: './users-list.component.html',
})
export class UsersListComponent implements OnInit {

  users: User[]; // users to be displayed on page
  initialUsers: User[]; // initial set of users, this remains unchanged, although users displayed on page can change after search
  loadedUser: UserBase; // used when we load additional info in the tooltip
  selectedUser: User; // when we hover a user or click on it, it becomes selected
  loading: boolean;

  constructor(
    private usersService: UsersService,
    private searchService: SearchService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.selectedUser = new User();
    this.loadedUser = new UserBase();
    this.getUsers();
  }

  getUsers(): void {
    this.usersService.getUsers().subscribe(
      (response) => {
        this.initialUsers = this.users = this.searchService.initialUsers = response;
        this.loading = false;
      });
  }

  onSelect(user: User): void {
    this.selectedUser = user;
    this.usersService.username = user.login;
    this.usersService.getUserBaseInfo().subscribe(
      (response) => {
        this.loadedUser = response;
      });
  }
  onLeave(user: User): void {
    if (this.selectedUser = user) {
      this.selectedUser = new User();
    }
  }
  onKey(event: any) {
    this.searchService.hint = event.target.value;
    this.users = this.searchService.search();
  }

}
