import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../types/user';
import { UserBase } from '../types/user-base';
import { UserRepo } from '../types/user-repo';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  getUsersUrl = 'https://api.github.com/users?page=0&per_page=1000';
  username: string;
  error: boolean;

  constructor(
    private http: HttpClient,
  ) { }

  getUsers(): Observable<User[]> {
    this.error = false;
    return this.http.get<User[]>(this.getUsersUrl).pipe(
      tap(() => this.log('users loaded succesfully')),
      catchError(this.handleError('getUsers', []))
    );
  }
  getUserBaseInfo(): Observable<UserBase> {
    this.error = false;
    return this.http.get<UserBase>('https://api.github.com/users/' + this.username).pipe(
      tap(() => this.log('user loaded succesfully')),
      catchError(this.handleError('getUsers', new UserBase()))
    );
  }
  getUserRepos(): Observable<UserRepo[]> {
    this.error = false;
    return this.http.get<UserRepo[]>('https://api.github.com/users/' + this.username + '/repos').pipe(
      tap(() => this.log('user repos loaded succesfully')),
      catchError(this.handleError('getUserRepos', []))
    );
  }

  private log(message: string) {
    console.log(message);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead of writing a log
      this.log(`${operation} failed: ${error.message}`);
      this.error = true;
      return of(result as T);
    };
  }

}
