import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../types/user';

@Component({
  selector: 'app-user-short-info',
  templateUrl: './user-short-info.component.html'
})
export class UserShortInfoComponent implements OnInit {

  @Input() user: User;

  constructor() { }

  ngOnInit() {
  }

}
