import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { AboutComponent } from './components/about/about.component';
import { TaskComponent } from './components/task/task.component';

const routes: Routes = [
  { path: '', component: UsersListComponent },
  { path: 'about', component: AboutComponent },
  { path: 'task', component: TaskComponent },
  { path: 'detail/:username', component: UserDetailComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
